<template>
  <Header :cNames="cname"></Header>  
  <!-- <nav id="nav">
    <div><img src="./assets/img/icon.png" alt="与你相练"></div>
    <div>
      <router-link :style="'color:' + aColor" to="/" active-class="active">首页</router-link>
      <router-link :style="'color:' + aColor" to="/productInfo" active-class="active">管理系统</router-link>
      <router-link :style="'color:' + aColor" to="/about" active-class="active">关于我们</router-link>
      <router-link to="/work" active-class="active">商务合作</router-link>
      <router-link class="login" to="/login" active-class="active">登录</router-link>
    </div>
  </nav> -->
  <router-view @changeColor="changeColor" @cNames="cNames"></router-view>
  <!-- <FooterVue v-if="false"></FooterVue> -->


</template>

<script setup>
import {reactive, ref, onMounted} from 'vue';
import router from './router/index';
import Header from './components/HeaderVue.vue';
// import FooterVue from './components/FooterVue.vue';
let aColor = ref('');
let cname=ref('')
// let footerBln = ref(true);
router.afterEach((to) => {
  if(to.name === 'productInfo'){
    aColor.value = '#000';
  }else{
    aColor.value = '#fff';
  }
})
const changeColor = (value)=>{
  aColor.value = value;
}
const cNames=(e)=>{
  console.log(e,111)
  cname.value=e
}
</script>

<style lang="scss">
html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}
li{
  list-style: none;
}
a {
  text-decoration: none;
  cursor: pointer;
}
@font-face {
  font-family: 'SourceRegular';
  src: url(./assets/fonts/SourceHanSansCN/SourceHanSansCN-Regular.otf);
}
@font-face {
  font-family: 'SourceBold';
  src: url(./assets/fonts/SourceHanSansCN/SourceHanSansCN-Bold.otf);
}
@font-face {
  font-family: 'SourceNormal';
  src: url(./assets/fonts/SourceHanSansCN/SourceHanSansCN-Normal.otf);
}
@font-face {
  font-family: 'SourceLight';
  src: url(./assets/fonts/SourceHanSansCN/SourceHanSansCN-Light.otf);
}
@font-face {
  font-family: 'BebasNeue';
  src: url(./assets/fonts/BebasNeue-1.otf);
}
img{
  vertical-align: middle;
  object-fit: contain;
}
#app {
  height: 100%;
  font-family: "Microsoft Yahei";
}

 html,
 body {
   height: 100%;
   padding: 0;
   margin: 0;
 }

 li {
   list-style: none;
 }

 a {
   text-decoration: none;
   cursor: pointer;
 }

 #app {
   height: 100%;
   font-family: "Microsoft Yahei";
 }

 #footer {
   height: 20%;
   background-color: #000;
   color: #fff;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   font-size: 12px;
   position: fixed;
   bottom: 0;
   width: 100%;

   span {
     margin: 8px;
   }

  a {
    margin-left: 20px;
    color: #fff;
  }
  .active{
    color: #FF2D55 !important;
  }
  .login {
    font-size: 12px;
    padding: 5px 20px;
    border: 1px solid;
    border-radius: 20px;
  }
}
.flex {
  display: flex;
  align-items: center;
}

.flex-between {
  justify-content: space-between;
}

.flex-around {
  justify-content: space-around;
}

.flex-center {
  justify-content: center;
}

.flex-column {
  flex-direction: column;
}

.flex-left {
  align-items: flex-start;
}

.flex-right {
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-end {
  justify-content: flex-end;
}

.flex-1 {
  flex: 1;
}
</style>
