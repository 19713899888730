<template>
	<div>
		<div class="banner_box">
			<!-- <video id="myVideo" src="//flv.cndns.com/cn/webnew/images/cndns-banner-video-2.mp4" width="100%" autoplay="autoplay" loop="loop" muted="muted" class="video">您的浏览器不支持 video 标签。</video> -->
			<img class="banner" src="@/assets/imgs/video.jpg" alt="">
			<img class="slogan" src="@/assets/imgs/slogan.png" alt="">
		</div>
		<!-- 开启健康运动生活 -->
		<div class="demo_box">
			<p>开启健康运动生活</p>
			<span>多种私教模式、健康饮食计划、专业动作教学、精准运动数据、有料内容社区<br/>丰富你的运动世界</span>
			<div class="demo_con flex flex-wrap flex-between">
				<img :src="item" v-for="(item, index) in demos" :key="index" alt="">
			</div>
		</div>
	</div>

	<div ref="footer">
		<FooterVue class="section"></FooterVue>
	</div>

	<!-- <RightCode v-show="show"></RightCode> -->
	<RightCode v-show="show"></RightCode>
	<div class="mpCode_box" v-show="show">
		<img class="code" :src="imgs || require('@/assets/imgs/footerMpCode.png')" alt="">
		<p class="codeFont" v-if="!imgs">扫码使用Ragana小程序</p>
	</div>
	<CommentVue></CommentVue>
</template>

<script>
	import FooterVue from '../components/FooterVue.vue';
	import RightCode from '../components/RightCode.vue';
	import CommentVue from '../components/CommentVue.vue';
	export default {
		components: {
			FooterVue,
			RightCode,
			CommentVue
		},
		data() {
			return {
				demos: [require('@/assets/imgs/app1.png'), require('@/assets/imgs/app2.png'), require(
						'@/assets/imgs/app3.png'), require('@/assets/imgs/app4.png'), require(
					'@/assets/imgs/app5.png'), require('@/assets/imgs/app6.png'), require('@/assets/imgs/app7.png'),
					require('@/assets/imgs/app8.png'), require('@/assets/imgs/app9.png'), require(
						'@/assets/imgs/app10.png')
				],
				show: true,
				rect: ''
			}
		},
		mounted() {
			window.addEventListener("scroll", this.scrolling);
		},
		methods: {
			scrolling() {
				// 滚动条距文档顶部的距离
				let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
				if (this.$refs.footer) {
					this.rect = this.$refs.footer.getBoundingClientRect()
				}

				if (scrollTop > (this.rect.top + 1200)) {
					this.show = false
				} else {
					this.show = true
				}
			}
		}

	}
</script>

<style lang="scss" scoped>
	.banner_box {
		width: 100%;
		height: 100vh;
		overflow: hidden;
		position: relative;

		.banner {
			width: 100%;
		}

		.video {
			width: 100%;
			height: auto;
		}

		.slogan {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 856px;
		}
	}

	.code_box::v-deep {
		bottom: 330px;
	}

	.mpCode_box {
		position: fixed;
		right: 30px;
		bottom: 134px;
		width: 160px;
		img{
			width: 100%;
		}


		.codeFont {
			position: absolute;
			bottom: 12px;
			font-size: 12px;
			font-family: SourceRegular;
			font-weight: 400;
			color: #333333;
			width: 100%;
			text-align: center;
		}
	}

	.demo_box {
		margin-top: 124px;
		margin-bottom: 100px;

		p {
			font-size: 70px;
			font-family: SourceBold;
			font-weight: bold;
			color: #000000;
			text-align: center;
			margin-top: 0;
			margin-bottom: 20px;
		}

		span {
			font-size: 24px;
			font-family: SourceNormal;
			color: #666666;
			text-align: center;
			display: block;
		}

		.demo_con {
			margin-top: 48px;
			padding-bottom: 20px;
			overflow: hidden;
			display: flex;
			flex-wrap: wrap;

			img {
				width: 18.6%;
				// height: 755px;
				object-fit: contain;

				&:nth-child(1),
				&:nth-child(6) {
					margin-left: -52px;
				}

				&:nth-child(5),
				&:nth-child(10) {
					margin-right: -52px;
				}

				&:nth-child(1),
				&:nth-child(3),
				&:nth-child(5) {
					margin-top: 110px;
				}

				&:nth-child(6),
				&:nth-child(8),
				&:nth-child(10) {
					margin-top: 62px;
				}

				&:nth-child(2),
				&:nth-child(4),
				&:nth-child(7),
				&:nth-child(9) {
					// margin-top: -48px;
					-webkit-animation: fadeInDown .8s ease-in-out forwards, slideUpDown 6s linear .8s infinite;
					animation: fadeInDown .8s ease-in-out forwards, slideUpDown 6s linear .8s infinite
				}
				&:nth-child(7),
				&:nth-child(9) {
					margin-top: -60px;
				}

				&:nth-child(1),
				&:nth-child(3),
				&:nth-child(5),
				&:nth-child(6),
				&:nth-child(8),
				&:nth-child(10) {
					-webkit-animation: fadeInUp .8s ease-in-out forwards, slideDownUp 6s linear .8s infinite;
					animation: fadeInUp .8s ease-in-out forwards, slideDownUp 6s linear .8s infinite
				}
			}
		}
	}


	@-webkit-keyframes slideUpDown {

		from,
		to {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0)
		}

		50% {
			-webkit-transform: translate3d(0, -1%, 0);
			transform: translate3d(0, -1%, 0)
		}
	}

	@keyframes slideUpDown {

		from,
		to {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0)
		}

		50% {
			-webkit-transform: translate3d(0, -1%, 0);
			transform: translate3d(0, -1%, 0)
		}
	}

	@-webkit-keyframes slideDownUp {

		from,
		to {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0)
		}

		50% {
			-webkit-transform: translate3d(0, 1%, 0);
			transform: translate3d(0, 1%, 0)
		}
	}

	@keyframes slideDownUp {

		from,
		to {
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0)
		}

		50% {
			-webkit-transform: translate3d(0, 1%, 0);
			transform: translate3d(0, 1%, 0)
		}
	}
</style>