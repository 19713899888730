/**
 * 全站http配置
 *
 * axios参数说明
 * isToken是否需要token
 */
import axios from 'axios';
import { ElMessage } from 'element-plus'

//默认超时时间
axios.defaults.timeout = 120000;
//返回其他状态码
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500;
};
//http request拦截
axios.interceptors.request.use(config => {
  //开启 progress bar
  const meta = (config.meta || {});
  
  //headers中配置text请求
  if (config.text === true) {
    config.headers["Content-Type"] = "text/plain";
  }
  if(config.data && config.data.ids){
    // config.headers["Content-Type"] = config.ContentType;
    config.url = config.url + '?ids=' + config.data.ids;
  }
  return config
}, error => {
  return Promise.reject(error)
});
//http response 拦截
axios.interceptors.response.use(res => {
  // if(typeof res.data === 'string' && res.data.length !== 0 && res.data.length < 20){
  //   ElMessage({
  //     message: res.data,
  //     type: 'error'
  //   });
  //   let ele = document.querySelector('.el-loading-mask');
  //   if(ele){
  //     ele.style.display = 'none';
  //   }
  //   return
  // }
  //获取状态码
  const status = res.data.code || res.status;
  const message = res.data.msg || res.data.error_description || '未知错误';
  // 如果请求为非200否者默认统一处理
  if (status !== 200) {
    ElMessage({
      message: message,
      type: 'error'
    });
    return Promise.reject(new Error(message))
  }
  return res;
}, error => {
  return Promise.reject(new Error(error));
});

export default axios;
