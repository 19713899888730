<template>
  <div>
    <img @click="msgBtn" class="comment_btn" src="@/assets/imgs/msg.png" alt="">

    <div class="comment_box" v-if="msgShow" @click="msgBtn"></div>
    <div class="comment_con" v-if="msgShow">
      <div class="comment_hearder_box">留言咨询</div>
      <div class="comment_form_box">
        <div class="comment_form_item_box">
          <p>姓名<span>*</span></p>
          <el-input type="text" placeholder="请在此输入您的姓名" v-model="msgForm.name" maxlength="10" show-word-limit></el-input>
        </div>
        <div class="comment_form_item_box">
          <p>手机号码<span>*</span></p>
          <el-input type="text" placeholder="请在此输入手机号码" v-model="msgForm.phone" maxlength="11" :controls="false"></el-input>
        </div>
        <div class="comment_form_item_box comment_form_item_box1">
          <p>留言<span>*</span></p>
          <el-input type="textarea" placeholder="请输入您想要了解或者您疑惑的内容，有任何问题，都可以在这里描述，我们工作人员第一时间看到会及时与您联系。" v-model="msgForm.messageContent" maxlength="300" show-word-limit></el-input>
        </div>
        <div class="comment_form_btn_box">
          <el-button type="primary" @click="msgBtn">取消</el-button>
          <el-button type="primary" @click="msgComfire">提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineEmits,
  onBeforeUnmount
} from 'vue';
import { ElMessage } from 'element-plus'
import { throttle } from "@/util/util";

import { msgSave } from '@/api/msg'
const msgShow = ref(false)
const msgBtn = () => {
  msgForm.value = {
    name: '',
    phone: '',
    messageContent: ''
  }
  msgShow.value = !msgShow.value
}
// 表单参数
const msgForm = ref({
  name: '',
  phone: '',
  messageContent: ''
})
// 表单提交
const msgComfire = () => {
  throttle(() => {
    const reg = /^1[3-9]\d{9}$/;
    if (!msgForm.value.name) {
      ElMessage.error('请输入姓名')
      return
    }
    if (!msgForm.value.phone) {
      ElMessage.error('请输入手机号码')
      return
    }
    if (!reg.test(msgForm.value.phone)) {
      ElMessage.error('请输入正确的手机号码')
      return
    }
    if (!msgForm.value.messageContent) {
      ElMessage.error('请输入留言')
      return
    }
    msgSave(msgForm.value).then(res => {
      if (res.data.code == 200) {
        ElMessage.success('提交成功')
        msgShow.value = false
      } else {
        ElMessage.error(res.msg)
      }
    })
  }, 500)

}
</script>

<style lang="scss" scoped>
.comment_btn {
  width: 160px;
  object-fit: contain;
  position: fixed;
  right: 30px;
  bottom: 60px;
  cursor: pointer;
}

.comment_box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($color: #000000, $alpha: 0.3);
  z-index: 999;
  backdrop-filter: blur(8px);
}

.comment_con {
  width: 996px;
  height: 580px;
  background: linear-gradient(180deg, #FFF9FA 16%, #FFF3F5 26%, #FFFFFF 94%);
  border-radius: 10px 10px 10px 10px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1000;

  .comment_hearder_box {
    height: 92px;
    background: url('@/assets/imgs/msgBg.png') no-repeat center;
    background-size: 100%;
    padding-left: 84px;
    box-sizing: border-box;
    font-family: SourceBold;
    // font-weight: bold;
    font-size: 30px;
    color: #FFFFFF;
    line-height: 92px;
  }

  .comment_form_box {
    padding: 56px 84px 46px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .comment_form_item_box {
      display: flex;
      align-items: center;
      margin-bottom: 38px;

      &.comment_form_item_box1 {
        align-items: flex-start;

        p {
          margin-top: 14px;
        }
      }

      p {
        font-family: SourceNormal;
        font-weight: 400;
        font-size: 20px;
        margin-right: 14px;
        flex-shrink: 0;

        span {
          color: #E50A33;
        }
      }

      .el-input {
        width: 282px;
        height: 44px;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #FFECEC;
        font-size: 16px;

        ::v-deep .el-input__wrapper {
          box-shadow: none;

          &.is-focus {
            box-shadow: none;
          }
        }
      }

      .el-textarea {
        width: 764px;
        height: 198px;
        background: #FFFFFF;
        border-radius: 6px;
        border: 1px solid #FFECEC;
        font-size: 16px;

        ::v-deep .el-textarea__inner {
          box-shadow: none !important;
          height: 100% !important;
          padding: 20px;
          box-sizing: border-box;
        }
      }

    }

    .comment_form_btn_box {
      display: flex;
      justify-content: center;
      width: 100%;

      .el-button {
        width: 156px;
        height: 44px;
        background: #E50A33;
        border-radius: 6px 6px 6px 6px;
        border: none;
        font-family: SourceRegular;
        font-weight: 400;
        font-size: 20px;
        color: #FFFFFF;

        &:first-child {
          background: transparent;
          border: 1px solid #E50A33;
          color: #E50A33;
          margin-right: 28px;
        }
        &:last-child{
          margin-left: 0;
        }
      }
    }
  }
}
</style>