<template>
  <div class="code_box">
    <img class="code" :src="imgs || require('@/assets/imgs/footerCode.png')" alt="">
    <p class="codeFont" v-if="!imgs">扫码下载Ragana APP</p>
  </div>
</template>

<script>
export default {
  props: ['imgs'],
  data() {
    return {

    }
  },
  methods: {

  }
}  
</script>

<style lang="scss">
.code_box {
  position: fixed;
  right: 30px;
  bottom: 134px;
  width: 160px;
  img{
    width: 100%;
  }

  .codeFont {
    position: absolute;
    bottom: 12px;
    font-size: 12px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #333333;
    width: 100%;
    text-align: center;
  }
}
</style>