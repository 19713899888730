<template>
  <div class="one-footer_box">
    <div class="one-footer" ref="footer">
      <div class="footer_box flex flex-between">
        <div class="footer_left_box">
          <div class="footer_left_top">
            <router-link to="/"><img src="@/assets/imgs/logo1.png" alt=""></router-link>
            <p>Ragana，与你相练</p>
          </div>
          <div class="footer_left_bottom">
            <span>服务热线</span>
            <p>0351-2379580</p>
          </div>
        </div>
        <div class="footer_center_box flex flex-between">
          <div class="footer_nav_box">
            <router-link to="/" class="nav_right_item" active-class="active">首页</router-link>
          </div>
          <div class="footer_nav_box flex flex-column flex-left">
            <router-link to="/productInfo" class="nav_right_item" active-class="active">产品介绍</router-link>
            <router-link to="/productInfo?1" class="nav_right_item" active-class="active">上门私教</router-link>
            <router-link to="/productInfo?2" class="nav_right_item" active-class="active">场地私教</router-link>
            <router-link to="/productInfo?3" class="nav_right_item" active-class="active">线上私教</router-link>
            <router-link to="/productInfo?4" class="nav_right_item" active-class="active">运动健身</router-link>
            <router-link to="/productInfo?5" class="nav_right_item" active-class="active">健身社区</router-link>
            <!-- <router-link to="/productInfo?5" class="nav_right_item" active-class="active">直播互动</router-link> -->
          </div>
          <div class="footer_nav_box flex flex-column flex-left">
            <router-link to="/CoachInfo" class="nav_right_item" active-class="active">教练入驻</router-link>
            <router-link to="/CoachInfo?1" class="nav_right_item" active-class="active">无忧入驻</router-link>
            <router-link to="/CoachInfo?2" class="nav_right_item" active-class="active">时间灵活</router-link>
            <router-link to="/CoachInfo?3" class="nav_right_item" active-class="active">海量订单</router-link>
            <router-link to="/CoachInfo?4" class="nav_right_item" active-class="active">提现秒到</router-link>
          </div>
          <!-- <div class="footer_nav_box flex flex-column flex-left">
            <router-link to="/GymInfo" class="nav_right_item" active-class="active">健身房入驻</router-link>
            <router-link to="/GymInfo?1" class="nav_right_item" active-class="active">海量曝光</router-link>
            <router-link to="/GymInfo?2" class="nav_right_item" active-class="active">多样营收</router-link>
            <router-link to="/GymInfo?3" class="nav_right_item" active-class="active">提现便捷</router-link>
          </div> -->
          <div class="footer_nav_box flex flex-column flex-left">
            <router-link to="/AboutInfo" class="nav_right_item" active-class="active">关于我们</router-link>
            <router-link to="/AboutInfo?1" class="nav_right_item" active-class="active">品牌理念</router-link>
            <router-link to="/AboutInfo?2" class="nav_right_item" active-class="active">联系我们</router-link>
          </div>
        </div>
        <div class="footer_right_code_box">
          <div class="footer_right_box flex flex-column">
            <img src="@/assets/imgs/rightCode.png" alt="">
            <p>扫码下载Ragana APP</p>
          </div>
          <div class="footer_right_box flex flex-column">
            <img src="@/assets/imgs/rightMpCode.png" alt="">
            <p>扫码使用Ragana小程序</p>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright_box">
      <div class="copyright_item_box flex flex-center">
        <span>Copyright © 2023 天津万唯计算机技术有限公司. All Rights Reserved.</span>
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">津ICP备2022008875号</a>
        <span>组织机构代码：91120116MAC0N8321M</span>
        <span>广播电视节目制作经营许可证：（津）字第1833号</span>
      </div>
      <div class="copyright_item_box flex flex-center">
        <a href="https://jbts.mct.gov.cn" target="_blank">12318全国文化市场举报</a>
        <span>网络文化经营许可证：津网文（2023）0337-007号</span>
        <a href="https://www.qinglangtianjin.com" target="_blank">有害信息举报</a>
        <a href="https://www.12377.cn" target="_blank">违法和不良信息举报中心</a>
      </div>
    </div>
    <!-- <div class="code_box" v-show="show">
      <img class="code" src="@/assets/imgs/footerCode.png" alt="">
      <p class="codeFont">扫码下载Ragana APP</p>
    </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: true,
      rect: ''
    }
  },
  watch: {
    $route(to, from) {
      console.log(to.path)
      if (to.path == '/') {
        this.show = true
      } else {
        this.show = false
      }
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrolling);
  },
  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

      if (scrollTop > (this.rect.top + 760)) {
        this.show = false
      } else {
        this.show = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.one-footer_box {
  height: 375px !important;
}

.one-footer {
  background: #222222;

  .footer_box {
    width: 81%;
    padding: 48px 0;
    margin: 0 auto;
    align-items: start;

    .footer_left_top {
      p {
        font-size: 14px;
        font-family: SourceLight;
        font-weight: 300;
        color: #FFFFFF;
        margin-top: 12px;
        margin-bottom: 0;
      }
    }

    .footer_left_bottom {
      margin-top: 70px;

      span {
        font-family: SourceLight;
        font-size: 14px;
        color: #FFFFFF;
      }

      p {
        font-size: 24px;
        color: #FFFFFF;
        // font-weight: bold;
        font-family: BebasNeue;
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .footer_center_box {
      width: 36%;
      align-items: start;

      .footer_nav_box {
        .nav_right_item {
          font-size: 14px;
          font-family: SourceNormal;
          font-weight: 400;
          color: #999;
          margin-bottom: 12px;

          &:first-child {
            font-size: 18px;
            color: #F1F1F1;
            font-family: SourceLight;
            margin-bottom: 18px;
          }

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
    .footer_right_code_box{
      display: flex;
      align-items: center;
    }
    .footer_right_box {
      &:first-child{
        margin-right: 40px;
      }
      p {
        font-size: 14px;
        font-family: SourceLight;
        font-weight: 300;
        color: #FFFFFF;
        margin-top: 14px;
      }
    }
  }
}

.copyright_box {
  background: #222222;
  border-top: 1px solid rgba(102, 102, 102, 0.4);
  padding: 22px 0;

  span,
  a {
    font-size: 12px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #666666;
    margin: 0 6px;
  }

  a:hover {
    color: #E50A33;
  }
}

.code_box {
  position: fixed;
  right: 180px;
  bottom: 60px;

  .codeFont {
    position: absolute;
    bottom: 12px;
    font-size: 12px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #333333;
    width: 100%;
    text-align: center;
  }
}
</style>
